<template>
  <div class="exhibitor">
    <div class="search-wrapper">
      <a-form-model
        :model="entity"
        :layout="'inline'"
        @submit.prevent="handleSearch"
      >
        <a-form-model-item prop="name" label="名称">
          <a-input
            v-model="entity.name"
            type="text"
            placeholder="输入搜索"
            allowClear
            @change="
              () => {
                if (entity.name == '') delete entity.name;
              }
            "
          ></a-input>
        </a-form-model-item>
        <a-form-model-item>
          <a-space>
            <a-button
              icon="search"
              :loading="loading"
              type="primary"
              htmlType="submit"
            >
              搜索
            </a-button>
            <a-button
              icon="redo"
              :loading="loading"
              htmlType="reset"
              @click="handleReset"
            >
              重置
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="table-wrapper">
      <y-table
        :columns="columns"
        :loading="loading"
        :service="service"
        :entity="entity"
        :filters="filters"
        @add="handleClickAdd"
      >
        <template slot="action" slot-scope="action">
          <a-button type="link" @click="handleEditClick(action.record)"
            >编辑</a-button
          >
          <!-- <a-button type="link" @click="handleDeleteClick(action.record)"
            >删除</a-button
          > -->
        </template>
      </y-table>
    </div>
    <editor
      :visible.sync="visible"
      :target="target"
      @close="handleEditorClose"
    ></editor>
  </div>
</template>

<script>
import UserService from "../../services/user";

import Editor from "./editor";

const defaultEntity = {
  properties: {},
};

export default {
  components: { Editor },
  data() {
    return {
      service: UserService,
      visible: false,
      visibleSortModal: false,
      loading: false,
      entity: JSON.parse(JSON.stringify(defaultEntity)),
      target: {},
      columns: [
        {
          dataIndex: "portrait",
          title: "头像",
          sorter: true,
        },
        {
          dataIndex: "name",
          title: "名称",
          sorter: true,
        },
        {
          dataIndex: "sequence",
          title: "权重",
          scopedSlots: { customRender: "sequence" },
        },
        {
          dataIndex: "creater",
          title: "创建人",
        },
        {
          dataIndex: "createTime",
          title: "创建时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "updater",
          title: "更新人",
        },
        {
          dataIndex: "updateTime",
          title: "更新时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "enabled",
          title: "状态",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    handleSearch() {
      this.entity = JSON.parse(JSON.stringify(this.entity));
    },
    handleReset() {
      this.entity = JSON.parse(JSON.stringify(defaultEntity));
    },
    handleClickAdd() {
      this.target = {};
      this.visible = true;
    },
    handleEditClick(record) {
      this.target = record;
      this.visible = true;
    },
    handleEditorClose() {
      this.entity = JSON.parse(JSON.stringify(this.entity));
    },
    async handleDeleteClick(record) {
      try {
        this.loading = true;
        await this.service.deleteEntity(record);
      } catch (error) {
        error.response ? error.response.data.message : error.message;
      } finally {
        this.entity = JSON.parse(JSON.stringify(this.entity));
        this.loading = false;
      }
    },
  },
};
</script>

<style>
</style>